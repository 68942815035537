import React from "react";
import Lottie from "react-lottie-wrapper";

const Component = () => {
  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: {
            v: "5.2.1",
            fr: 30,
            ip: 0,
            op: 180,
            w: 1920,
            h: 1080,
            nm: "letters black",
            ddd: 0,
            assets: [
              {
                id: "comp_0",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [135.663, 48.557, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.209, -5.8],
                                  [11.601, 0.23],
                                  [10.211, 5.8],
                                  [-11.599, -0.7],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.11372499913,
                                0.11372499913,
                                0.11372499913,
                                0.10000000149,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 10, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Path",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [132.878, 56.443, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.209, -5.799],
                                  [11.601, 0.231],
                                  [10.211, 5.801],
                                  [-11.599, -0.699],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.11372499913,
                                0.11372499913,
                                0.11372499913,
                                0.10000000149,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 10, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Path",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [165.742, 45.662, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [-1.1, 3.2],
                                  [0, 0],
                                  [-3.07, -0.89],
                                  [0, 0],
                                  [1.11, -3.25],
                                  [0, 0],
                                  [3.1, 0.96],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [1.04, -3.03],
                                  [0, 0],
                                  [3.3, 0.95],
                                  [0, 0],
                                  [-1.05, 3.07],
                                  [0, 0],
                                  [-3.23, -1.01],
                                ],
                                v: [
                                  [-51.728, 6.964],
                                  [-37.318, -34.876],
                                  [-29.988, -38.686],
                                  [47.722, -16.206],
                                  [51.732, -8.486],
                                  [36.792, 34.874],
                                  [29.342, 38.654],
                                  [-47.838, 14.644],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "st",
                            c: {
                              a: 0,
                              k: [
                                0.11372499913,
                                0.11372499913,
                                0.11372499913,
                                1,
                              ],
                              ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0.928, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.13333333333333333,
                                0.28627450980392155,
                                0.7019607843137254,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: "Path 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [10.625, 175.581, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0.204, 0.11],
                                  [-14.98, 13.18],
                                  [30.58, -4.861],
                                  [0.215, -0.061],
                                ],
                                o: [
                                  [17.26, -1.44],
                                  [0, 0],
                                  [0.276, 0.304],
                                  [3.965, 9.439],
                                ],
                                v: [
                                  [8.986, 9.21],
                                  [75.046, -32.13],
                                  [6.296, -0.22],
                                  [-1.465, 0.606],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.11372499913,
                                0.11372499913,
                                0.11372499913,
                                0.079999998212,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 8, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [-77.841, 180.556, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [-10.375, -2.303],
                                  [-7.8, 9.11],
                                  [-0.43, 0.21],
                                  [0.62, -0.72],
                                  [3.16, -31.24],
                                  [25.658, -1.858],
                                ],
                                o: [
                                  [22.747, 5.049],
                                  [-0.04, -0.41],
                                  [0.27, -0.13],
                                  [0.27, 3.01],
                                  [-24.56, 23.96],
                                  [-16.665, 1.207],
                                ],
                                v: [
                                  [90.716, -40.253],
                                  [194.406, -115.273],
                                  [194.966, -116.193],
                                  [194.406, -115.273],
                                  [202.606, -71.703],
                                  [97.006, 4.237],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.992156982422,
                                0.254902005196,
                                0.254902005196,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: "Path",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [132.507, 76.346, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [3.59, -4.04],
                                  [7.19, -8.99],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [-3.59, 3.59],
                                  [-3.9, 3.9],
                                  [6.29, 3.15],
                                  [-3.6, 5.84],
                                ],
                                o: [
                                  [-2.88, 3.24],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [8.54, -3.15],
                                  [2.88, -2.88],
                                  [0.45, -5.99],
                                  [-8.13, -4.06],
                                  [3.59, -5.84],
                                ],
                                v: [
                                  [9.786, -39.402],
                                  [-6.394, -7.942],
                                  [-40.994, 25.308],
                                  [-33.804, 40.138],
                                  [-11.784, 20.368],
                                  [9.786, 1.498],
                                  [29.106, -7.942],
                                  [38.096, -24.122],
                                  [38.096, -39.402],
                                  [9.786, -24.122],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.800000011921, 0.800000011921, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                ],
              },
            ],
            layers: [
              {
                ddd: 0,
                ind: 1,
                ty: 0,
                nm: "h1",
                refId: "comp_0",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [-18],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 45,
                        s: [-18],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 90,
                        s: [0],
                        e: [-18],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 135,
                        s: [-18],
                        e: [0],
                      },
                      { t: 180 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [999.5, 509.5, 0], ix: 2 },
                  a: { a: 0, k: [14, 161, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                w: 224,
                h: 200,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [794.091, 573.513, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [8.59, -23.81],
                              [0, 0],
                              [0, 0],
                              [-9.58, 2.85],
                              [-6.74, -6.29],
                              [0, 0],
                              [-3.08, 28.4],
                              [-9.44, 29.66],
                              [10.8, -4.25],
                            ],
                            o: [
                              [-3.41, 9.44],
                              [0, 0],
                              [13.3, -10.06],
                              [-41.7, 68.31],
                              [0, 0],
                              [0, 0],
                              [2.24, -20.57],
                              [3.51, -11.05],
                              [-23.55, 9.27],
                            ],
                            v: [
                              [-37.874, -28.036],
                              [-40.124, -2.426],
                              [-30.234, -22.646],
                              [16.046, -44.666],
                              [-18.554, 62.734],
                              [40.316, 54.644],
                              [16.046, 8.814],
                              [28.636, -57.696],
                              [8.406, -54.546],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.11372499913,
                            0.11372499913,
                            0.11372499913,
                            0.050000000745,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 5, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [996.625, 524.581, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [19.22, -18.34],
                              [0, 0],
                              [-2.85, 0.6],
                              [-13.48, 2.25],
                              [1.079, -0.361],
                            ],
                            o: [
                              [-12.29, 11.73],
                              [10.34, -22.92],
                              [25.88, 5.39],
                              [0.389, -0.291],
                              [-24.309, 8.118],
                            ],
                            v: [
                              [-59.764, -4.27],
                              [-75.044, 32.13],
                              [-54.374, 4.72],
                              [8.986, 9.21],
                              [10.546, -0.72],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.11372499913,
                            0.11372499913,
                            0.11372499913,
                            0.079999998212,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 8, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [908.159, 529.556, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [16.78, -3.89],
                              [-17.37, 30.41],
                              [11.38, -7.03],
                              [0, 0],
                              [7.34, 5.09],
                              [0, 0],
                              [-15.73, -13.93],
                              [-10.78, 0],
                              [-9.89, -3.14],
                              [-24.46, -5.9],
                              [21.335, -2.793],
                              [15.28, 0],
                              [5.84, -8.53],
                              [-0.45, -18.87],
                              [9.44, 4.5],
                              [12.58, 5.4],
                              [13.03, 5.84],
                            ],
                            o: [
                              [-15.1, -12.22],
                              [-8.62, 0],
                              [0, 0],
                              [-10.06, 2.52],
                              [0, 0],
                              [27.86, -28.76],
                              [14.6, 12.94],
                              [0, 0],
                              [9.88, 3.15],
                              [22.65, 5.47],
                              [-31.893, 4.175],
                              [-15.28, 0],
                              [-5.84, 8.54],
                              [0.45, 18.87],
                              [-9.44, -4.49],
                              [-12.58, -5.39],
                              [-10.42, -4.67],
                            ],
                            v: [
                              [-132.624, 106.687],
                              [-99.374, 0.187],
                              [-144.304, 21.307],
                              [-168.574, 74.337],
                              [-203.174, 62.207],
                              [-172.164, -11.493],
                              [-31.064, -84.293],
                              [-0.064, -78.453],
                              [26.456, -78.453],
                              [90.716, -40.253],
                              [97.006, 4.237],
                              [34.546, 0.187],
                              [1.736, 57.257],
                              [-7.244, 97.257],
                              [-23.874, 114.327],
                              [-54.434, 114.327],
                              [-69.264, 100.847],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.992156982422,
                            0.254902005196,
                            0.254902005196,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 5,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [700.756, 889.772, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-19.02, 13.51],
                              [-9.12, -9.96],
                              [14.52, -4.95],
                            ],
                            o: [
                              [0, 0],
                              [7.79, 16.1],
                              [8.63, 9.42],
                              [0, 0],
                            ],
                            v: [
                              [-31.275, -5.398],
                              [-16.835, -26.038],
                              [10.655, -5.398],
                              [26.945, 26.042],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.992156982422,
                            0.254902005196,
                            0.254902005196,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 6,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [865.601, 918.315, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-12.23, 19.87],
                              [-19.23, -10.75],
                              [11.43, -10.23],
                            ],
                            o: [
                              [-11.61, -1.15],
                              [13.8, 8.52],
                              [11.15, 6.23],
                              [0, 0],
                            ],
                            v: [
                              [-26.645, 12.285],
                              [-27.395, -15.415],
                              [5.975, -7.155],
                              [33.255, 15.415],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.992156982422,
                            0.254902005196,
                            0.254902005196,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 7,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [851.479, 892.839, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-5.844, 22.021],
                              [12.137, -22.019],
                              [-3.594, -14.379],
                              [-12.133, 22.021],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.11372499913,
                            0.11372499913,
                            0.11372499913,
                            0.029999999329,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 3, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 8,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [846.762, 740.951, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [10.34, 26.66],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, -31.46],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [-41.34, -46.74],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 43.3],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [13.253, 32.357],
                              [-69.428, -106.053],
                              [-69.428, -131.213],
                              [33.033, -147.393],
                              [45.163, -97.513],
                              [69.423, 32.357],
                              [29.432, 147.397],
                              [-5.617, 147.397],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.11372499913,
                            0.11372499913,
                            0.11372499913,
                            0.140000000596,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 14, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 9,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [805.642, 740.951, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-1.94, 3.95],
                              [-7.57, -8.56],
                              [0, 0],
                              [0, 0],
                              [0, 43.3],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-0.86, -2.06],
                              [0, 0],
                              [4.31, -16.54],
                              [0, 0],
                              [-31.01, 40.89],
                            ],
                            o: [
                              [6.82, 9.44],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, -31.46],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0.68, 1.75],
                              [0, 0],
                              [-24.87, 28.46],
                              [0, 0],
                              [0, 0],
                              [2.53, -3.34],
                            ],
                            v: [
                              [32.755, 5.177],
                              [54.375, 32.357],
                              [35.495, 147.397],
                              [70.555, 147.397],
                              [110.545, 32.357],
                              [86.275, -97.513],
                              [74.145, -147.393],
                              [-28.315, -131.213],
                              [-28.315, -106.053],
                              [-26.005, -100.323],
                              [-30.555, -3.593],
                              [-110.545, 102.457],
                              [-88.075, 126.277],
                              [26.065, 16.177],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.192157000303,
                            0.243137001991,
                            0.694118022919,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 10,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [917.515, 387.904, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [2.18, -4],
                              [2.17, 4],
                              [-4.36, 13.43],
                              [-4.72, 0],
                              [-4, 8.71],
                              [5.81, -15.25],
                              [1.45, -6.9],
                              [8.35, -3.63],
                              [2.17, 0.36],
                              [5.45, 0.36],
                            ],
                            o: [
                              [-1.74, 3.19],
                              [-2.91, -9.8],
                              [3.47, -10.7],
                              [4.72, 0],
                              [3.99, -8.71],
                              [-4.68, 12.29],
                              [-1.46, 6.9],
                              [-8.35, 3.63],
                              [-2.18, -0.37],
                              [-5.45, -0.36],
                            ],
                            v: [
                              [-28.891, 36.605],
                              [-36.871, 32.605],
                              [-30.701, -5.875],
                              [-12.191, -22.575],
                              [16.129, -33.465],
                              [36.819, -22.575],
                              [18.309, -6.965],
                              [-7.831, -2.245],
                              [-14.001, 22.445],
                              [-23.081, 13.005],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.094117999077, 0.117646999657, 0.33333298564, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 11,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [698.918, 856.888, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [4.715, -16.177],
                              [-14.605, 12.133],
                              [-13.255, 16.173],
                              [14.605, -6.287],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.11372499913,
                            0.11372499913,
                            0.11372499913,
                            0.059999998659,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 6, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 12,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [902.743, 418.398, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, -11.98],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-2.33, 6.1],
                              [-0.58, 1.39],
                              [-5.45, 12.76],
                              [27.95, -2.54],
                            ],
                            o: [
                              [0, 11.98],
                              [0, 0],
                              [0, 0],
                              [0.48, -1.94],
                              [0.97, -4.65],
                              [7.98, 2.31],
                              [5.45, -12.75],
                              [-31.95, 2.9],
                            ],
                            v: [
                              [-19.565, 1.022],
                              [-27.445, 29.702],
                              [-30.715, 38.422],
                              [2.945, 48.442],
                              [0.865, 29.702],
                              [5.115, 19.092],
                              [23.635, 13.312],
                              [12.745, -48.348],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.800000011921, 0.800000011921, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 13,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [705.437, 621.009, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [10.06, -1.8],
                              [-12.29, 14.83],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0.97, -11.17],
                              [0, 0],
                              [5.03, -1.08],
                              [-2.1, 5.1],
                            ],
                            o: [
                              [-10.07, 1.79],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-0.32, 3.68],
                              [-2.4, 8.39],
                              [-5.04, 1.07],
                              [-7.79, 6.3],
                            ],
                            v: [
                              [-26.649, 37.263],
                              [-2.379, -4.987],
                              [5.391, -13.967],
                              [16.181, -37.337],
                              [29.211, -31.947],
                              [19.321, -4.987],
                              [19.321, 4.453],
                              [9.301, 30.073],
                              [9.301, 16.133],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.800000011921, 0.800000011921, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 14,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [705.66, 853.07, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [6.068, -24.49],
                              [-23.142, 18.199],
                              [-9.212, 24.49],
                              [23.138, -7.861],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.800000011921, 0.800000011921, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 15,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [858.668, 886.998, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-18.874, 25.165],
                              [-13.034, 1.345],
                              [-8.984, -25.165],
                              [18.876, -17.075],
                              [-4.044, 25.165],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.800000011921, 0.800000011921, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 16,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1374.495, 262.625, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-104.49, -25.13],
                              [-54, 56.25],
                              [-33, -18.75],
                            ],
                            o: [
                              [18.75, -33.78],
                              [118.5, 28.5],
                              [43.2, -45],
                              [0, 0],
                            ],
                            v: [
                              [-221.745, 74.625],
                              [-85.005, 40.845],
                              [81.495, -42.405],
                              [221.745, -58.905],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 4.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        d: [{ n: "d", nm: "dash", v: { a: 0, k: 15, ix: 1 } }],
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          n: ["0p833_0p833_0p167_0p167"],
                          t: 155,
                          s: [0],
                          e: [100],
                        },
                        { t: 175 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          n: ["0p833_0p833_0p167_0p167"],
                          t: 0,
                          s: [0],
                          e: [100],
                        },
                        { t: 20 },
                      ],
                      ix: 2,
                    },
                    o: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          n: ["0p833_0p833_0p167_0p167"],
                          t: 15,
                          s: [0],
                          e: [360],
                        },
                        { t: 155 },
                      ],
                      ix: 3,
                    },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 17,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1313.882, 234.138, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-104.49, -25.13],
                              [-54, 56.25],
                              [-33, -18.75],
                            ],
                            o: [
                              [-7.5, -42.75],
                              [118.5, 28.5],
                              [43.2, -45],
                              [0, 0],
                            ],
                            v: [
                              [-207.628, 87.358],
                              [-97.378, 28.108],
                              [69.122, -55.142],
                              [209.372, -71.642],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 4.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        d: [{ n: "d", nm: "dash", v: { a: 0, k: 15, ix: 1 } }],
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          n: ["0p833_0p833_0p167_0p167"],
                          t: 160,
                          s: [0],
                          e: [100],
                        },
                        { t: 180 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          n: ["0p833_0p833_0p167_0p167"],
                          t: 5,
                          s: [0],
                          e: [100],
                        },
                        { t: 25 },
                      ],
                      ix: 2,
                    },
                    o: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          n: ["0p833_0p833_0p167_0p167"],
                          t: 20,
                          s: [0],
                          e: [360],
                        },
                        { t: 160 },
                      ],
                      ix: 3,
                    },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 18,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [915, 524.446, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-379.869, -160.571],
                              [0.001, 160.569],
                              [379.871, -160.571],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.11372499913,
                            0.11372499913,
                            0.11372499913,
                            0.009999999776,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 1, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 19,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [915, 620, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [838.5, 514.5], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 45.75, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.9254901960784314, 0.9450980392156862, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 20,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [915.75, 875, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-554.25, 0],
                              [554.25, 0],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 4.5, ix: 5 },
                        lc: 2,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 21,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [-15],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 30,
                        s: [-15],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 60,
                        s: [0],
                        e: [-15],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 90,
                        s: [-15],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 120,
                        s: [0],
                        e: [-15],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 150,
                        s: [-15],
                        e: [0],
                      },
                      { t: 180 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [514.505, 868.44, 0], ix: 2 },
                  a: { a: 0, k: [94.633, 98.039, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-121.5, -54.75],
                              [64.5, -46.5],
                            ],
                            o: [
                              [-72, -67.5],
                              [-37.45, 27],
                            ],
                            v: [
                              [95.869, 96.351],
                              [-87.881, -88.149],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.094117999077,
                            0.207843005657,
                            0.494118005037,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 22,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 5,
                        s: [0],
                        e: [-9],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 35,
                        s: [-9],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 65,
                        s: [0],
                        e: [-9],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 95,
                        s: [-9],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 125,
                        s: [0],
                        e: [-9],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 155,
                        s: [-9],
                        e: [0],
                      },
                      { t: 185 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [539.745, 877.67, 0], ix: 2 },
                  a: { a: 0, k: [99.616, 140.736, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-96.75, -44.25],
                              [85.5, -40.5],
                            ],
                            o: [
                              [-93.54, -77.25],
                              [-58.5, 27.71],
                            ],
                            v: [
                              [95.117, 138.814],
                              [-81.133, -134.186],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 23,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 10,
                        s: [0],
                        e: [-10],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 40,
                        s: [-10],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 70,
                        s: [0],
                        e: [-10],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 100,
                        s: [-10],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 130,
                        s: [0],
                        e: [-10],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 160,
                        s: [-10],
                        e: [0],
                      },
                      { t: 190 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [539.745, 877.67, 0], ix: 2 },
                  a: { a: 0, k: [55.216, 152.083, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-74.25, -46.5],
                              [84.75, -31.5],
                            ],
                            o: [
                              [-60, -77.25],
                              [-55.5, 47.25],
                            ],
                            v: [
                              [55.216, 152.083],
                              [-35.534, -149.417],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.094117999077,
                            0.207843005657,
                            0.494118005037,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 24,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [15],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 30,
                        s: [15],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 60,
                        s: [0],
                        e: [15],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 90,
                        s: [15],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 120,
                        s: [0],
                        e: [15],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 150,
                        s: [15],
                        e: [0],
                      },
                      { t: 180 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [1312.5, 866.752, 0], ix: 2 },
                  a: { a: 0, k: [-95.872, 96.351, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [121.5, -54.75],
                              [-64.5, -46.5],
                            ],
                            o: [
                              [72, -67.5],
                              [37.46, 27],
                            ],
                            v: [
                              [-95.872, 96.351],
                              [87.878, -88.149],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.094117999077,
                            0.207843005657,
                            0.494118005037,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 25,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 5,
                        s: [0],
                        e: [9],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 35,
                        s: [9],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 65,
                        s: [0],
                        e: [9],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 95,
                        s: [9],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 125,
                        s: [0],
                        e: [9],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 155,
                        s: [9],
                        e: [0],
                      },
                      { t: 185 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [1288.5, 877.67, 0], ix: 2 },
                  a: { a: 0, k: [-99.613, 140.736, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [96.75, -44.25],
                              [-85.5, -40.5],
                            ],
                            o: [
                              [93.54, -77.25],
                              [58.5, 27.71],
                            ],
                            v: [
                              [-95.113, 138.814],
                              [81.137, -134.186],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 26,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 10,
                        s: [0],
                        e: [10],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 40,
                        s: [10],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 70,
                        s: [0],
                        e: [10],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 100,
                        s: [10],
                        e: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 130,
                        s: [0],
                        e: [10],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 160,
                        s: [10],
                        e: [0],
                      },
                      { t: 190 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [1288.5, 877.67, 0], ix: 2 },
                  a: { a: 0, k: [-55.214, 152.083, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [74.25, -46.5],
                              [-84.75, -31.5],
                            ],
                            o: [
                              [60, -77.25],
                              [55.5, 47.25],
                            ],
                            v: [
                              [-55.214, 152.083],
                              [35.536, -149.417],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.11372499913, 0.11372499913, 0.11372499913, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.094117999077,
                            0.207843005657,
                            0.494118005037,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
            ],
            markers: [],
          },
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        width="100%"
      />
    </>
  );
};

export default Component;
