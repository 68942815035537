import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import logo from "../../images/logo.png";
import Email from "../components/animation/Email";

const Footer = (props) => {
  const {
    company,
    postamble,
    contact: { email },
  } = props.data.site.siteMetadata;
  return (
    <>
      <center>
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <Email />
        </div>
      </center>
      <div style={{ textAlign: "center" }}>
        <Typography component="span" gutterBottom paragraph variant="body1">
          {postamble}
        </Typography>
        <p />
        <Typography color="primary" component="span" variant="h5">
          <span style={{ opacity: "0.2" }}>&lt;</span> {email}{" "}
          <span style={{ opacity: "0.2" }}>&gt;</span>
        </Typography>
      </div>
      <Divider style={{ marginTop: "48px", marginBottom: "48px" }} />
      <footer
        id="footer"
        style={{ marginBottom: "24px", whiteSpace: "nowrap" }}
      >
        <div style={{ textAlign: "center" }}>
          <Typography component="span" variant="caption">
            ©{new Date().getFullYear()} {company}
            <br />
            We're in Portland, Oregon
          </Typography>
          <p />
          <img alt="Aizu Solutions Logo" src={logo} style={{ width: "80px" }} />
        </div>
      </footer>
    </>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            company
            postamble
            contact {
              email
            }
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
);
